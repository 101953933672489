<ng-template #notificacion let-title="Nuevo paquete">
    <div [ngStyle]="{'padding-top': '50%', 'height': innerHeight, 'width':innerWidth, 'background': 'rgb(255,255,255)', 'background': 'linear-gradient(90deg, rgba(255,254,254,0) 9%, rgba(83,109,254,0.9531162806919643) 100%)'}">
        
        <table width="100%">
            <td width="50%">
                <div class="container-notification">
                    <div class="container-detail" style="padding-top: 20px;">
                        <div align="left" class="descrip-notification">Hola <span class="name-notification">{{fullName}}</span>
                        </div>
                        <div align="left" class="new-notification">Hay un nuevo envío</div>
                        <div align="left" class="new-notification-2">publicado cerca de una de tus rutas:</div>
                        <div class="label-notification">Detalles:</div>
                        <div class="title-notification">{{titlePackage.substr(0, 60)}} ...</div>
                        <div class="descrip-notification">{{descripPackage.substr(0, 60)}}...</div>
                    </div>
                    <br />
                    <table width="100%" style="padding-top: 10px;padding-bottom: 10px;">
                        <td width="50%" valign="middle" class="distance-notification">
                            <div align="center" *ngIf="distancePackage > 0">
                                Estas a : <br />{{distancePackage | number:'1.1-2'}} KM
                            </div>
                            <div align="center"
                                *ngIf="distancePackage === 0 || !distancePackage || distancePackage === '' || distancePackage === undefined || distancePackage === null || distancePackage === 'null'">
                                Estas muy cerca
                            </div>
                        </td>
                        <td width="50%" valign="middle" class="price-notification">
                            <div align="center">
                                Ofrece: <br />${{pricePackage}}
                            </div>
                        </td>
                    </table>
        
                    <div class="container-detail">
                        <div class="descrip-notification">Recoger en:</div>
                        <div class="dir-notification">{{fromPackage}}</div>
                        <div class="descrip-notification">Entregar en:</div>
                        <div class="dir-notification">{{toPackage}}</div>
                        <br/>
                        <table width="100%" style="padding-top: 15px;">
                            <td width="20%" valign="middle" (click)="goDriverProfile(item.IdDriver)">
                                <div align="right" style="padding-left: 5px;">
                                    <ngx-avatar facebookId="{{userPhotoPackage}}" size="100" *ngIf="userPlatform === true">
                                    </ngx-avatar>
                                    <ngx-avatar src="{{userPhotoPackage}}" size="100" *ngIf="userPlatform === false">
                                    </ngx-avatar>
                                </div>
                            </td>
                            <td width="80%" valign="middle" (click)="goDriverProfile(item.IdDriver)">
                                <div class="label-2-notification">Publicado por:</div>
                                <div class="user-notification">{{userPackage}}</div>
                                <ngx-stars [readonly]="true" [maxStars]="5" [initialStars]="4.5" [color]="'#ffaa00'" [size]="1"
                                    [animationSpeed]="200"></ngx-stars>
                            </td>
                        </table>
        
                        <table width="100%">
                            <td width="50%">
                                <div align="center">
                                    <div class="button-acept" (click)="accept(idPackage)">Ver detalles</div>
                                </div>
                            </td>
                            <td width="50%">
                                <div align="center">
                                    <div class="button-cancel" (click)="stopAudio()">Cancelar</div>
                                </div>
                            </td>
                        </table>
                    </div>
                </div>
            </td>
            <td width="50%" valign="bottom">
                <div align="left">
                    <img src="assets/img/notification_img.png" style="width: 650px;height: auto;">
                </div>
            </td>
        </table>
    </div>
</ng-template>
<simple-notifications [options]="options" (destroy)="destroyed($event)"></simple-notifications>
<router-outlet></router-outlet>