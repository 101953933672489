<footer class="footer bg-blue-80 pt6 pb8">
    <div class="container">
        <div class="row flex-sm flex-align-center-sm flex-row-sm flex-justify-start-sm">
            <div class="col-sm-6 col-md-5 mb6 mb0-sm flex-shrink-0">
                <img src="../../../assets/img/footer_ico.png"
                    class="img-responsive w-50% w-auto-sm nmt12-md lazyload">
            </div>
            <div class="col-sm-6 col-md-7">
                <div class="text-white kilo mb3">
                   ¡Falta poco!
                </div>
                <div class="text-white big mb5">
                    {{dataTranslate.TITLE_FOOTER_2}}
                </div>
                <div class="mb2">
                    <a href="#" target="_blank">
                        <img class="mr4" height="40" alt="iPhone"
                            src="../../../assets/img/app_store_icon.svg" />
                    </a>
                    <a href="#" target="_blank">
                        <img height="40" alt="Android"
                            src="../../../assets/img/play_store_icon.svg" />
                    </a>
                </div>
            </div>
        </div>
        <hr class="b--white-100 mt6 mb6 mb10-sm">

        <div  class="row flex flex-wrap flex-align-center">

            <div  style="width: 170px;padding-left: 20px;">
                <ul class="list-unstyled">
                    <li><a class="block text-white mb1" href="/how-we-work"> {{dataTranslate.OPTION_FOOTER_1}}</a></li>
                </ul>
            </div>
             <!--<div  style="width: 170px;padding-left: 20px;">
                <ul class="list-unstyled">
                    <li><a class="block text-white mb1" href="https://encomana.cl/dashboard/sign-in" target="_blank"> {{dataTranslate.OPTION_FOOTER_2}}</a></li>
                </ul>
            </div>
            <div  style="width: 170px;padding-left: 20px;">
                <ul class="list-unstyled">
                    <li><a class="block text-white mb1" href="#"> {{dataTranslate.OPTION_FOOTER_3}}</a></li>
                </ul>
            </div>
            <div  style="width: 220px;padding-left: 20px;">
                <ul class="list-unstyled">
                    <li><a href="#" class="block text-white mb1"> {{dataTranslate.OPTION_FOOTER_4}}</a></li>
                </ul>
            </div>
            <div  style="width: 150px;padding-left: 20px;">
                <ul class="list-unstyled">
                    <li><a href="#" class="block text-white mb1"> {{dataTranslate.OPTION_FOOTER_5}}</a></li>
                </ul>
            </div>
            <div  style="width: 50px;padding-left: 20px;">
                <ul class="list-unstyled">
                    <li><a href="/"
                        class="link-unstyled text-white mh2 w-24 h-24 flex flex-align-center flex-justify-center opacity-80 hover:opacity-100 transition-all">
                        <i class="fa fa-facebook f3"></i>
                    </a></li>
                </ul>
            </div>
            <div  style="width: 50px;padding-left: 20px;">
                <ul class="list-unstyled">
                    <li><a href="/"
                        class="link-unstyled text-white mh2 w-24 h-24 flex flex-align-center flex-justify-center opacity-80 hover:opacity-100 transition-all">
                        <i class="fa fa-instagram f3"></i>
                    </a></li>
                </ul>
            </div>-->

        </div>
        <div class="row mt8">
            <div class="col-xs-8 col-sm-5 hidden-xs">
                <div class="text-white title2">
                    &copy; Encomana.com {{dateNow}}. All Rights Reserved. 
                </div>
            </div>
        </div>
    </div>
</footer>