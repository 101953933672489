import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs/index';
import { environment } from 'src/environments/environment.prod';
import { UserService } from './user.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class DriverService {

  private _url: string;
  private _secret_key: string;
  public cookiesTokenData: any;
  public token: any;

  constructor(private _http: HttpClient, public _userService: UserService, private cookieService: CookieService) {
    this._url = environment.url;
    this._secret_key = environment.secret_key;
  }

  getToken() {
    this.cookiesTokenData = this._userService.getUserToken();
    if (this.cookiesTokenData) {
      this.token = this.cookiesTokenData.token;
    }
  }
  getNotificationNewPackage(lat: any, lng: any, range: any, fromCommune: any, ToCommune: any): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}notification-new-package/${lat}/${lng}/${range}/${fromCommune}/${ToCommune}`, { headers: headers });
  }

  getPackageDetail(id: number, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}package-detail/${id}`, { headers: headers });
  }
  
  applyPackage(data: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}apply-for-package`, params, { headers: headers });
  }

  
  deletePossibleDriver(id: number, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/delete-possible-drivers/${id}`, { headers: headers });
  }

  checkPossibleDriver(id: any, idPackages: any): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}check-possible-drivers/${id}/${idPackages}`, { headers: headers });
  }

  getPublicProfileDriver(id: number, user?: any): Observable<any> {
    return this._http.get(`${this._url}get-driver-info/${id}/${user}`);
  }

  getUserTransportPublic(id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`${this._url}get-user-transport-public/${id}`, { headers: headers });
  }
  
}
