import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { UserService } from 'src/app/services/user.service';
import { DriverService } from 'src/app/services/driver.service';
import { WebsocketService } from './../../services/websocket.service';
import { RealTimeService } from './../../services/real-time.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isLoading: boolean;
  public viewError: boolean;
  public user: boolean;
  public cookiesUserData: any;
  public cookiesTokenData: any;
  public active: boolean;
  public userActice: any;
  public idUser: number;
  public fullName: string;
  public imgProfileUser: string;
  public telfUser: string;
  public emailUser: string;
  public direccionUser: string;
  public cityUser: number;
  public countryUser: number;
  public imgBgUser: string;
  public communeUser: string;
  public typeUser: string;
  public type: any;
  public platformUser: string;
  public validatePlatfom: boolean;
  public dataTranslate: any;
  public messageResp: string;
  public titleResp: string;
  public newLang: any;
  public newMenu: any;
  public dataTranslateMenu: any;
  langs: string[] = [];
  public lang: any;
  public href: any = "";
  public titleOption: any;
  public urlOption: any;

  title: string = 'Winter is coming';
  @ViewChild('example') example: TemplateRef<any>;
  public message: any;
  public dataFrom: any;
  public dataPendientes: any;
  public viewUser: boolean;
  public dataNotificationAct: any;
  public noNotificationAct: boolean;
  public viewNewLabel: boolean;
  public viewOldLabel: boolean;
  public dataNotificationActSin: any;

  @Input() viewHeader: boolean;

  constructor(private cookieService: CookieService,
    private _realTimeService: RealTimeService,
    private _globalService: GlobalService,
    private _translateService: TranslateService,
    private _router: Router,
    private _service: NotificationsService,
    private _userService: UserService,
    private _driverServices: DriverService) {

    this.langs = this._translateService.getLangs();
    this.isLoading = false;
  }

  ngOnInit() {
    //this.checkUser();
    this.lang = this._translateService.currentLang;
    this._translateService.get('HOME')
      .subscribe((res: any) => {
        this.dataTranslate = res;
      });
   
  
     // Mensaje nuevo.
     this._realTimeService.receiveMessage().subscribe(msg =>{
      if (msg['message'].to === this.idUser) {
        this._service.remove();
        this.viewUser = false;
        this._globalService.getUserInfo(msg['message'].from).subscribe(
          res => {
            this.dataFrom = res['user'][0];
            this.viewUser = true;
            this.MessageNoRead(msg['message'].to);
          }, (error) => {

            this._router.navigateByUrl('/error-500');
          });
        // Notificaciones
        this.message = msg['message'].message;
        let context: any = {
          clickToClose: true,
        }
        const toast = this._service.html(this.example, context);

        toast.click.subscribe((event) => {
          this._router.navigateByUrl('/chat');
        });
      } else {
      }
    },(error) => {
      console.log(error);
    });

    
    // Notificación de una nueva actividad.
    this._realTimeService.receiveNotification().subscribe(msg =>{
      if ( msg['notification'].to === this.idUser) {
        this.getNotificationActivity(this.idUser);
      }
    },(error) => {
      console.log(error);
    });

  }
  
  goDashboard(url: string){
    window.open(url, "_blank");
  }

  MessageNoRead(to: any) {
    this._globalService.totalMessageNoRead(to).subscribe(
      res => {
        this.dataPendientes = res['pendientes'][0];
      }, (error) => {
        this._router.navigateByUrl('/error-500');
      });
  }


  getNotificationActivity(id: any): void {
    this.isLoading = true;
    this._globalService.getNotificationActivity(id, '', this._userService.getCountryData('country'), this._userService.getCountryData('zone'), this._userService.getCountryData('utc')).subscribe(
      res => {
        this.isLoading = false;
        if (res['result'].length > 0) {
          // No leidos
          if (res['result'][0].notificationNoRead.length > 0) {
            this.viewNewLabel = true;
            this.dataNotificationActSin = res['result'][0].notificationNoRead;
          } else {
            this.dataNotificationActSin = [];
            this.viewNewLabel = false;
          }

          // Leidos
          if (res['result'][1].notificationRead.length > 0) {
            this.viewOldLabel = true;
            this.dataNotificationAct = res['result'][1].notificationRead;
          } else {
            this.viewOldLabel = false;
          }

          if (res['result'][0].notificationNoRead.length === 0 && res['result'][1].notificationRead.length === 0) {
            this.noNotificationAct = true;
          } else {
            this.noNotificationAct = false;
          }
        }

      }, (error) => {
        this._router.navigateByUrl('/error-500');
      });
  }


  checkUser() {
    this.user = false;
    this.cookiesUserData = this._userService.getUserData();
    if (this.cookiesUserData) {
      this.userActice = this.cookiesUserData;
      if (this.userActice) {
        this.active = true;
        this.fullName = this.userActice.user[0].Name;
        this.idUser = this.userActice.user[0].IdUser;
        this.telfUser = this.userActice.user[0].Phone;
        this.emailUser = this.userActice.user[0].Email;
        this.direccionUser = this.userActice.user[0].Address;
        this.cityUser = this.userActice.user[0].Region;
        this.countryUser = this.userActice.user[0].Pais;
        this.communeUser = this.userActice.user[0].Comuna;
        this.imgProfileUser = this.userActice.user[0].Photo;
        this.platformUser = this.userActice.user[0].Platform;
        this.type = this.userActice.user[0].UserType;
        if (this.platformUser.substr(0, 8) === 'Facebook') {
          this.validatePlatfom = true;
        } else {
          this.validatePlatfom = false;

          this.MessageNoRead(this.idUser);
          this.getNotificationActivity(this.idUser);

          if (this.type === 1) {
            this._translateService.use(this._translateService.currentLang);
            this._translateService.get('MENU_USER').subscribe((resMenu: any) => {
              this.dataTranslateMenu = resMenu;
              this._globalService.setMenu(this.dataTranslateMenu);
            });
          } else {
            this._translateService.use(this._translateService.currentLang);
            this._translateService.get('MENU_DRIVER').subscribe((resMenu: any) => {
              this.dataTranslateMenu = resMenu;
              this._globalService.setMenu(this.dataTranslateMenu);
            });
          }
        }

      }
    } else {
      this.active = false;
      this._translateService.use(this._translateService.currentLang);
      this._translateService.get('MENU').subscribe((resMenu: any) => {
        this.dataTranslateMenu = resMenu;
        this._globalService.setMenu(this.dataTranslateMenu);
      });//this.fullName = this.dataTranslate.TITLE_PAGE;
    }
  }

 



  goChat() {
    this._router.navigateByUrl('/chat');
  }

  goRegister(){
    let url = 'https://encomana.cl/dashboard/sign-in';
    window.open(url, "_blank");
  }


  changeLang(lang: any) {
    this._translateService.use(lang);
    this.lang = lang;
    this._globalService.setLanguage(lang);
    this._translateService.get('HOME')
      .subscribe((res: any) => {
        this.dataTranslate = res;
        if (this.href === '/how-we-work') {
          this.titleOption = 'Home';
          this.urlOption = '/home';
          return;
        }
        if (this.href === '/home' || this.href === '/') {
          this.titleOption = this.dataTranslate.OPTION_BAR_1;
          this.urlOption = '/how-we-work';
          return;
        }
      });
    this._translateService.get('MENU_USER_1').subscribe((resMenu: any) => {
      this.dataTranslateMenu = resMenu;
      this._globalService.setMenu(this.dataTranslateMenu);
    });

  }


}
