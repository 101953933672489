import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public dateNow: string;
  public newLang: any;
  public dataTranslate: any;
  langs: string[] = [];
  public lang: any;

  constructor(private cookieService: CookieService,
    private _globalService: GlobalService,
    private _translateService: TranslateService,
    private _router: Router) {

    this.langs = this._translateService.getLangs();
    this.lang = this._translateService.currentLang;
    this._translateService.get('FOOTER')
      .subscribe((res: any) => {
        this.dataTranslate = res;
      });
  }

  ngOnInit() {
    moment.locale('es');
    this.dateNow = moment().format('YYYY');
    this._globalService.currentLanguaje.subscribe(newLang => {
      this.newLang = newLang;
      this._translateService.use(this.newLang);
      this._translateService.get('FOOTER')
        .subscribe((res: any) => {
          this.dataTranslate = res;
        });
    });

  }

}
