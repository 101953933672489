import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class RealTimeService {

  constructor(private _webSocket: WebsocketService) { }

  // Crear paquete
  sendPackage(drives: any, dataAlert: any, newPackage: any) {
    const payload: any = {
      drivers: drives,
      dataAlert: dataAlert,
      newPackage: newPackage
    };
    this._webSocket.sendEvent('send-new-package', payload);
  }

  receiveNewPackage() {
    return this._webSocket.receiveEvent('new-package');
  }

  //Mensajes
  sendMessage(from: any, to: any, message: string, idConversation: any) {
    const payload: any = {
      from: from,
      to: to,
      message: message,
      idConversation: idConversation
    };
    this._webSocket.sendEvent('send-message', payload);
  }

  readMessage(from: any, to: any, message: string, idConversation: any) {
    const payload: any = {
      from: from,
      to: to,
      message: message,
      idConversation: idConversation
    };
    this._webSocket.sendEvent('read-message', payload);
  }

  receiveReadMessage() {
    return this._webSocket.receiveEvent('new-read-message');
  }

  receiveMessage() {
    return this._webSocket.receiveEvent('new-message');
  }

  //Notificaciones
  sendNotification(from: any, to: any, type: string, title: any) {
    const payload: any = {
      from: from,
      to: to,
      type: type,
      title: title,
    };
    this._webSocket.sendEvent('send-notification', payload);
  }

  // Nueva notificación
  receiveNotification() {
    return this._webSocket.receiveEvent('new-notification');
  }

  // Aceptar propuesta
  newAcceptPackage() {
    return this._webSocket.receiveEvent('new-accept-package');
  }

  receiveAceptPackages() {
    return this._webSocket.receiveEvent('new-resp-accept-package');

  }

  // Nueva ruta
  receiveNewRoute() {
    return this._webSocket.receiveEvent('new-start-route');
  }

  sendRespAcceptPackage(idPackage: any, idUserCreator: any, idUserDelivers: any) {
    const payload: any = {
      idPackage: idPackage,
      idUserCreator: idUserCreator,
      idUserDelivers: idUserDelivers
    };
    this._webSocket.sendEvent('send-resp-accept-package', payload);
  }

  sendStartRoute(idUser: any, idDriver: any, idPackage: any) {
    const payload: any = {
      idUser: idUser,
      idDriver: idDriver,
      idPackage: idPackage,
    };
    this._webSocket.sendEvent('send-start-route', payload);
  }

  sendAcceptPackage(idPackage: any, idUserCreator: any, idUserDelivers: any, amountDelivers: any, dateAccept: any, status: string) {
    const payload: any = {
      idPackage: idPackage,
      idUserCreator: idUserCreator,
      idUserDelivers: idUserDelivers,
      amountDelivers: amountDelivers,
      dateAccept: dateAccept,
      status: 'status'
    };
    this._webSocket.sendEvent('send-accept-package', payload);
  }

  receiveAcceptPackage() {
    return this._webSocket.receiveEvent('new-accept-package');
  }

  sendProposedRejection(idUserDelivers: any, idPackage: any) {
    const payload: any = {
      idUserDelivers: idUserDelivers,
      idPackage: idPackage,
    };
    this._webSocket.sendEvent('send-proposed-rejection', payload);
  }

  receiveProposedRejection() {
    return this._webSocket.receiveEvent('new-proposed-rejection');
  }

  // Inicio de la ruta
  sendStartMap(idUser: any, idPackages: any) {
    const payload: any = {
      idUser: idUser,
      idPackages: idPackages,
    };
    this._webSocket.sendEvent('send-start-map', payload);
  }

  receiveNewStartMap() {
    return this._webSocket.receiveEvent('new-start-map');
  }

  // Cancelar ruta
  sendCancelRoute(idUser: any, idDriver: any, idPackage: any, reason: string) {
    const payload: any = {
      idUser: idUser,
      idDriver: idDriver,
      idPackage: idPackage,
      reason: reason
    };
    this._webSocket.sendEvent('send-cancel-route', payload)
  }

  receiveCancelRoute() {
    return this._webSocket.receiveEvent('new-cancel-route');
  }

  sendCancelRouteUser(idUser: any, idDriver: any, idPackage: any, reason: string) {
    const payload: any = {
      idUser: idUser,
      idDriver: idDriver,
      idPackage: idPackage,
      reason: reason
    };
    this._webSocket.sendEvent('send-cancel-route-user', payload)
  }

  receiveCancelRouteUser() {
    return this._webSocket.receiveEvent('new-cancel-route-user');
  }

  // Update status ruta
  sendUpdateStatus(idUser: any, idDriver: any, idPackage: any, status: string) {
    const payload: any = {
      idUser: idUser,
      idDriver: idDriver,
      idPackage: idPackage,
      status: status
    };
    this._webSocket.sendEvent('send-update-map', payload)
  }

  receiveUpdateStatus() {
    return this._webSocket.receiveEvent('new-update-map');
  }

  // Actualización de coordenadas
  sendStartCoordinates(client: any, idUser: any, idPackages: any, heading: number, rotationImage: any, lat: number, lng: number, ico: any, speed: number, mjs: string, time: any, distance: any, unid: string, status: string, urlPicUser: string, fullName: string, origin: any, destination: any, dataDriver: any, pickUp: string, tracking: any, rating: any) {
    const payload: any = {
      client: client,
      idUser: idUser,
      idPackages: idPackages,
      heading: heading,
      rotationImage: rotationImage,
      lat: lat,
      lng: lng,
      ico: ico,
      speed: speed,
      mjs: mjs,
      time: time,
      distance: distance,
      unid: unid,
      status: status,
      urlPicUser: urlPicUser,
      fullName: fullName,
      origin: origin,
      destination: destination,
      dataDriver,
      pickUp: pickUp,
      tracking: tracking,
      rating: rating
    };
    this._webSocket.sendEvent('send-start-coordinates', payload);
  }

  receiveNewStartCoordinates() {
    return this._webSocket.receiveEvent('new-start-coordinates');
  }

  // Solicitus de envío
  sendShippingRequest(idUser: any, idDriver: any) {
    const payload: any = {
      idUser: idUser,
      idDriver: idDriver
    };
    this._webSocket.sendEvent('send-shipping-request', payload)
  }

  receiveShippingRequest() {
    return this._webSocket.receiveEvent('new-shipping-request');
  }

   //Pagos
   sendPay(idPackage: any, payment_id: any, status: string, merchant_order_id: any, payment_type: string, detail: string) {
    const payload: any = {
      idPackage: idPackage,
      payment_id: payment_id,
      status: status,
      merchant_order_id: merchant_order_id,
      payment_type: payment_type,
      detail: detail
    };
    this._webSocket.sendEvent('send-pay', payload)
  }

  receivePay() {
    return this._webSocket.receiveEvent('new-pay');
  }

  // Notificar pago
  sendPayNotification(idPackage: any, idUser: any, name: string, userPhoto: string, idDriver: string, driverName: string, driverPhoto: string, payment_type: string, amountPaid: any, titlePackage: string, photoPackage: string) {
    const payload: any = {
      idPackage: idPackage,
      idUser: idUser,
      name: name,
      userPhoto: userPhoto,
      idDriver: idDriver,
      driverName: driverName,
      driverPhoto: driverPhoto,
      payment_type: payment_type,
      amountPaid: amountPaid,
      titlePackage: titlePackage,
      photoPackage: photoPackage
    };
    this._webSocket.sendEvent('send-pay-notification', payload)
  }

  receivePayNotification() {
    return this._webSocket.receiveEvent('new-pay-notification');
  }

   // Actualizar lista propuesta
   sendUpdateProposal(idPackage: any) {
    const payload: any = {
      idPackage: idPackage,
    };
    this._webSocket.sendEvent('send-update-proposal', payload)
  }

  receiveUpdateProposal() {
    return this._webSocket.receiveEvent('new-update-proposal');
  }

   // Logout de usuarios
   sendLogOut(idUser: any) {
    const payload: any = {
      idUser: idUser,
    };
    this._webSocket.sendEvent('send-logout', payload)
  }

  receiveLogOut() {
    return this._webSocket.receiveEvent('new-logout');
  }



}
