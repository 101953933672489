import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import * as CryptoJS from 'crypto-js';
import { AES, SHA256 } from 'crypto-js';
import { environment } from 'src/environments/environment.prod';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _url: string;
  private _secret_key: string;

  constructor(private _http: HttpClient, private cookieService: CookieService,) {
    this._url = environment.url;
    this._secret_key = environment.secret_key;
  }

  getUserData() {
    return JSON.parse(this.getSecureStorage('userData') || null);
  }

  getUserToken() {
    return JSON.parse(this.getSecureStorage('token') || null);
  }

  getUserDataCookie() {
    return JSON.parse(this.getSecureStorageCookie('userData') || null);
  }

  getUserTokenCookie() {
    return JSON.parse(this.getSecureStorageCookie('token') || null);
  }

  getCountryData(key: any) {
    return this.getSecureStorage(key) || null;
  }

  encrypt(value) {
    return AES.encrypt(value, this._secret_key).toString();
  }

  decrypt(key) {
    const bytes = AES.decrypt(key, this._secret_key) || false;
    try {
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return false;
    }
  }

  setSecureStorage(key: string, value) {
    localStorage.setItem(SHA256(key).toString(), this.encrypt(value));
  }

  getSecureStorage(key) {
    const value = localStorage.getItem(SHA256(key).toString());
    return (value) ? this.decrypt(value) : false;
  }

  removeSecureStorage(key) {
    localStorage.removeItem(SHA256(key).toString());
  }

  setSecureStorageCookie(key: string, value) {
    this.cookieService.set(SHA256(key).toString(), this.encrypt(value));
  }

  getSecureStorageCookie(key) {
    const value = this.cookieService.get(SHA256(key).toString());
    return (value) ? this.decrypt(value) : false;
  }

  removeSecureStorageCookie(key) {
    this.cookieService.delete(SHA256(key).toString());
  }

  validateUser(email: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`${this._url}validate-user/${email}`,
      { headers: headers });
  }

  unlockingUser(email: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`${this._url}unlocking-user/${email}`,
      { headers: headers });
  }
  
}
