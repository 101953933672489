import { Component, HostListener, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './services/global.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { DriverService } from './services/driver.service';
import { UserService } from './services/user.service';
import { WebsocketService } from './services/websocket.service';
import { RealTimeService } from './services/real-time.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isLoading: boolean;
  public viewError: boolean;
  public user: boolean;
  public cookiesUserData: any;
  public cookiesTokenData: any;
  public active: boolean;
  public userActice: any;
  public idUser: number;
  public fullName: string;
  public imgProfileUser: string;
  public telfUser: string;
  public emailUser: string;
  public direccionUser: string;
  public cityUser: number;
  public countryUser: number;
  public imgBgUser: string;
  public communeUser: string;
  public typeUser: string;
  public type: any;
  public platformUser: string;
  public validatePlatfom: boolean;
  public dataTranslate: any;
  public messageResp: string;
  public titleResp: string;
  public newLang: any;
  public newMenu: any;
  public dataTranslateMenu: any;
  langs: string[] = [];

  public dataIp: any;

  @ViewChild('notificacion') notificacion: TemplateRef<any>;
  public dataDrivers: any;
  public idPackage: any;
  public titlePackage: string;
  public descripPackage: string;
  public userPackage: string;
  public userPhotoPackage: string;
  public userPlatform: boolean;
  public pricePackage: string;
  public fromPackage: string;
  public toPackage: string;
  public distancePackage: any;
  public photoPackage: any;
  public innerHeight: any;
  public innerWidth: any
  public options: any;

  public audio: any;

  constructor(private _translateService: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    public _webSocket: WebsocketService,
    private _realTimeService: RealTimeService,
    private _userService: UserService,
    private _service: NotificationsService,
    private _driverServices: DriverService,
    private _globalService: GlobalService) {

    // Add languages
    this._translateService.addLangs(['es', 'en']);
    // Set the default language
    this._translateService.setDefaultLang('es');
    // Use a language
    this._translateService.use('es');
  }

  ngOnInit() {
    this.getIp();
    this.checkUser();
    this.innerWidth = window.innerWidth + 'px';
    this.innerHeight = window.innerHeight + 'px';


    // Notificaciones por nuevo paquete.
    this._realTimeService.receiveNewPackage().subscribe(msg =>{
      if (this.type === 2) {
        this.options = {
          maxStack: 1,
          position: ["middle", "left"]
        }
        this._driverServices.getNotificationNewPackage(msg['new_package'].LatFrom, msg['new_package'].LngFrom, msg['new_package'].Range, msg['new_package'].FromCommune, msg['new_package'].ToCommune).subscribe(
          respNotification => {
            this.dataDrivers = respNotification['drivers'];
            var index = this.dataDrivers.findIndex(i => i.IdDriver === this.idUser);
            if (index > -1) {
              // Notificaciones
              this.idPackage = msg['new_package'].IdPackage;
              this.titlePackage = msg['new_package'].Title;
              this.descripPackage = msg['new_package'].Descrip;
              this.userPackage = msg['new_package'].Nombre;
              this.pricePackage = msg['new_package'].Price;
              this.fromPackage = msg['new_package'].FromAddress;
              this.toPackage = msg['new_package'].ToAddress;
              this.distancePackage = this.dataDrivers[0].Distance;
              this.userPhotoPackage = msg['new_package'].UserPhoto;
              this.userPlatform = msg['new_package'].userPlatfom;
              this.photoPackage = msg['new_package'].Photo;
              let context: any = {
                clickToClose: true,
              }
              const toast = this._service.html(this.notificacion, context);
            }
          }, (error) => {
            this.isLoading = false;
            this.viewError = true;
          });
      }
    },(error) => {
      console.log(error);
    });

  }

  accept(idPackage) {
    let cod = this._userService.encrypt(idPackage.toString());
    cod = cod.replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
    this.router.navigateByUrl('/accept-package/' + cod);
  }

  playAudio() {
    this.audio = new Audio();
    this.audio.src = "assets/alert/new.mp3";
    this.audio.load();
    this.audio.play();
    this.audio.loop = true;
  }

  stopAudio() {
    this.audio.pause();
  }

  destroyed(envent: any) {
    this.stopAudio();
  }

  getIp() {
    // Datos IP
    if (!this._userService.getCountryData('city')) {
      this._globalService.getLocationData().subscribe(
        ipData => {
          this.dataIp = ipData;
          this._globalService.getPais(this.dataIp.country).subscribe(
            resPais => {
              if (resPais['country'][0].Active === 0) {
                this.router.navigateByUrl('/error-403');
              } else {
                this._userService.setSecureStorage('country_name', this.dataIp.country);
                this._userService.setSecureStorage('city', this.dataIp.city);
                this._userService.setSecureStorage('calling_code', resPais['country'][0].Prefijo);
                this._userService.setSecureStorage('country', JSON.stringify(resPais['country'][0].Id));
                this._userService.setSecureStorage('zone', JSON.stringify(resPais['country'][0].Zone));
                this._userService.setSecureStorage('utc', JSON.stringify(resPais['country'][0].UTC));
              }
            }, (error) => {
              this.isLoading = false;
              this.viewError = true;
            });
        }, (error) => {
          this.isLoading = false;
          this.viewError = true;
        });
    }

  }

  checkUser() {
    this.user = false;
    this.cookiesUserData = this._userService.getUserData();
    if (this.cookiesUserData) {
      this.userActice = this.cookiesUserData;
      if (this.userActice) {
        this.active = true;
        this.fullName = this.userActice.user[0].Name;
        this.idUser = this.userActice.user[0].IdUser;
        this.telfUser = this.userActice.user[0].Phone;
        this.emailUser = this.userActice.user[0].Email;
        this.direccionUser = this.userActice.user[0].Address;
        this.cityUser = this.userActice.user[0].Region;
        this.countryUser = this.userActice.user[0].Pais;
        this.communeUser = this.userActice.user[0].Comuna;
        this.imgProfileUser = this.userActice.user[0].Photo;
        this.platformUser = this.userActice.user[0].Platform;
        this.type = this.userActice.user[0].UserType;
        if (this.platformUser.substr(0, 8) === 'Facebook') {
          this.validatePlatfom = true;
        } else {
          this.validatePlatfom = false;
        }
      }
    } else {
      this.active = false;
      //this.fullName = this.dataTranslate.TITLE_PAGE;
    }
  }

}
