import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LottieAnimationViewModule } from 'ng-lottie';
import { FooterModule } from './footer/footer/footer.module';
import { HeaderModule } from './header/header/header.module';
import { CookieService } from 'ngx-cookie-service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AvatarModule } from 'ngx-avatar';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxStarsModule } from 'ngx-stars';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config: SocketIoConfig = { url: 'https://encomana-socket.herokuapp.com', options: {
  foceNew: true,
   transports: ["polling"],
} };


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        LottieAnimationViewModule.forRoot(),
        FooterModule,
        HeaderModule,
        RouterModule,
        NgxSkeletonLoaderModule,
        AvatarModule,
        SimpleNotificationsModule.forRoot(),
        NgxStarsModule,
        SocketIoModule.forRoot(config)
    ],
    exports: [],
    providers: [CookieService],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
