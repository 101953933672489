import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, from } from 'rxjs/index';
import { environment } from '../../environments/environment.prod';
import { SendMessageAdmin } from '../interfaces/send_message_admin.interface';
import { CookieService } from 'ngx-cookie-service';
import { NewMessage } from '../interfaces/new-message';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private _url: string;
  private _secret_key: string;
  public cookiesTokenData: any;
  public token: any;
  // Idioma
  private LanguageSource = new BehaviorSubject<string>('es');
  currentLanguaje = this.LanguageSource.asObservable();
  // Menú
  private menuSource = new BehaviorSubject<any>('');
  currentMenu = this.menuSource.asObservable();
  // Pendientes
  private pendientesSource = new BehaviorSubject<boolean>(true);
  currentPendientes = this.pendientesSource.asObservable();

  constructor(private _http: HttpClient, private cookieService: CookieService, private _userService: UserService) {
    this._url = environment.url;
    this._secret_key = environment.secret_key;
  }

  getToken() {
    this.cookiesTokenData = this._userService.getUserToken();
    if (this.cookiesTokenData) {
      this.token = this.cookiesTokenData.token;
    }
  }

  // Idioma
  public setLanguage(lag: string) {
    this.LanguageSource.next(lag);
  }
  // Menú
  public setMenu(menu: any) {
    this.menuSource.next(menu);
  }
  // Pendientes
  public setPendientes(act: boolean) {
    this.pendientesSource.next(act);
  }

  getLocationData(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`https://extreme-ip-lookup.com/json/?key=x5qMGxBRwacWaUeWSKlp`, { headers: headers });
}

  getPais(pais: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}/countries/${pais}`,
      { headers: headers });
  }

  getRegiones(id: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}/region/${id}`,
      { headers: headers });
  }

  getComunas(region: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}/communes/${region}`,
      { headers: headers });
  }

  sendMessageAdmin(data: SendMessageAdmin): Observable<any> {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this._url}contact-us`, params, { headers: headers });
  }

  getRoutes(limit: number, user?: any, country?: any, zone?: string, utc?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    return this._http.get(`${this._url}/get-routes/${limit}/${user}`,
      { headers: headers });
  }

  getPackages(limit: number, user?: any, country?: any, zone?: string, utc?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    return this._http.get(`${this._url}/get-packages/${limit}/${user}`,
      { headers: headers });
  }

  getPopularRoutes(ciudad: string, limit: number, country?: any, zone?: string, utc?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    return this._http.get(`${this._url}/popular-routes/${ciudad}/${limit}`,
      { headers: headers });
  }

  searchPackages(pais: number, termino: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}/search-packages/${pais}/${termino}`,
      { headers: headers });
  }

  searchRoutes(termino: string, lat?: any, lng?: any, user?: any, country?: any, zone?: string, utc?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    return this._http.get(`${this._url}/search-routes/${termino}/${lat}/${lng}/${user}`,
      { headers: headers });
  }

  getUsersMessages(user: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/get-users-messages/${user}`, { headers: headers });
  }


  getMessage(id: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-message/${id}`, { headers: headers });
}

  deleteMessage(id: any): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/delete-message/${id}`, { headers: headers });
  }

  newMessage(data: NewMessage, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    const params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}/new-message/`, params, { headers: headers });
  }

  getUsers(id: number): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/get-users/${id}`, { headers: headers });
  }

  getDrivers(id: number): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/get-drivers/${id}`, { headers: headers });
  }

  getUserInfo(id: number): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/get-user-data/${id}`, { headers: headers });
  }

  getConversation(to: number, from: number): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/get-id-conversation/${to}/${from}`, { headers: headers });
  }

  updateConversation(id: any, user: any): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}read-conversation/${id}/${user}`, { headers: headers });
  }

  totalMessageNoRead(id: any): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/message-no-read/${id}`, { headers: headers });
  }
  deleteConversation(id: any): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/delete-conversation/${id}`, { headers: headers });
  }


  followUser(id: any, user: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/follow-user/${id}/${user}`, { headers: headers });
  }

  unfollowUser(id: any, user: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/unfollow-user/${id}/${user}`, { headers: headers });
  }

  favoritesRoutes(idUser: any, idRoute: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/favorite-route/${idUser}/${idRoute}`, { headers: headers });
  }


  favoritesPackages(idUser: any, idPackage: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/favorite-package/${idUser}/${idPackage}`, { headers: headers });
  }

  getNotificationActivity(id : any, limit?: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}/get-notification-activity/${id}/${limit}`, { headers: headers });
  }

  messageByRoute(data: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    const params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}/message-by-route/`, params, { headers: headers });
  }

  messageByPackage(data: any, country?: any, zone?: string, utc?: string): Observable<any> {
    this.getToken();
    const params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}/message-by-package/`, params, { headers: headers });
  }

  getRoutesClose(lat: any, lng: any, rad: any, country?: any, zone?: string, utc?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Country', country);
    headers = headers.append('Zone', zone);
    headers = headers.append('Utc', utc);
    return this._http.get(`${this._url}/nearby-routes/${lat}/${lng}/${rad}`,
        { headers: headers });
  }


  encryptDataUrl(idPackage: any, idDriver: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`${this._url}decrypt-data-url/${idPackage}/${idDriver}`, { headers: headers });
  }

}
