<div class="sticky-md z-2-sm bg-white pv5 t0 box-border" style="margin: 0px !important;">

  <audio id="player" controls style="display: none !important;" src="">
    <source src="../../assets/alerts/alert.mp3">
  </audio>

  <nav class="bg-red-20 w-100% h-70 ph4-with-safe-area ph6-lg flex flex-align-center z-1">
    <a href="/" class="mr5 mr7-lg h-70 flex flex-align-center pb2">
      <img src="assets/img/logo.png" width="250" height="auto">
    </a>
    <div class="flex flex-grow h-70 ">

    </div>

    <div *ngIf="viewHeader">
      <div class="flex">
        <div class="flex">
          
          <div  class="action-secondary-button" *ngIf="!active" (click)="goRegister()" style="width: 230px;margin-right: 10px">
            <div align="center">
              ¡Regístrate gratis!
            </div>
          </div>

          <div class="action-button" *ngIf="!active" (click)="goDashboard('https://encomana.cl/dashboard/')" style="width: 180px;">
            <div align="center">
              Inicia sesión
            </div>
          </div>

          <div class="avatar-option-header" *ngIf="active">
            <div>
              <span class="title2" style="padding-right: 10px;">{{fullName}}</span>
              <button mat-icon-button [matMenuTriggerFor]="menuUser">
                <ngx-avatar facebookId="{{imgProfileUser}}" size="50" *ngIf="validatePlatfom"></ngx-avatar>
                <ngx-avatar src="{{imgProfileUser}}" size="50" *ngIf="!validatePlatfom"></ngx-avatar>
              </button>
            </div>
          </div>
          <div class="flex flex-align-center">
            <div class="h-24 w-2 bg-blue-100 opacity-10"></div>
          </div>
          <div *ngIf="active" style="padding-left: 20px;padding-top: 10px;">
            <mat-icon class="msj-notification-icon" *ngIf="dataPendientes?.Pendientes > 0" (click)="goChat()"
              matBadge="{{dataPendientes?.Pendientes}}" matBadgeColor="warn">chat_bubble_outline</mat-icon>
            <mat-icon class="msj-notification-icon" *ngIf="dataPendientes?.Pendientes === 0" (click)="goChat()">
              chat_bubble_outline</mat-icon>

            <mat-icon class="alert-notification-icon" [matMenuTriggerFor]="menuAlert"
              *ngIf="dataNotificationActSin?.length > 0" (click)="goNotification()"
              matBadge="{{dataNotificationActSin?.length}}" matBadgeColor="warn">notifications_none</mat-icon>

            <mat-icon class="alert-notification-icon" [matMenuTriggerFor]="menuAlert"
              *ngIf="dataNotificationActSin?.length === 0" (click)="goNotification()">notifications_none</mat-icon>
          </div>
        </div>
      </div>
    </div>

  </nav>

  <mat-menu #menuAlert="matMenu" class="mat-menu-panel-notification">
    <div style="padding: 20px;">
      <div class="title animated bounceInLeft delay-0.2s">
        <b>Notificaciones</b>
      </div>

      <div style="padding-top: 10px;" *ngIf="isLoading">
        <table width="100%">
          <tr>
            <td width="16%">
              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '50px', height: '50px' }">
              </ngx-skeleton-loader>
            </td>
            <td width="74%">
              <ngx-skeleton-loader [theme]="{width: '250px',height: '15px'}">
              </ngx-skeleton-loader><br />
              <ngx-skeleton-loader [theme]="{ width: '170px',  height: '10px' }">
              </ngx-skeleton-loader>
              <br />
              <ngx-skeleton-loader [theme]="{ width: '140px',  height: '10px' }">
              </ngx-skeleton-loader>
            </td>
            <td width="10%">
              <ngx-skeleton-loader [theme]="{width: '50px',height: '15px'}">
              </ngx-skeleton-loader><br />
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td width="16%">
              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '50px', height: '50px' }">
              </ngx-skeleton-loader>
            </td>
            <td width="74%">
              <ngx-skeleton-loader [theme]="{width: '250px',height: '15px'}">
              </ngx-skeleton-loader><br />
              <ngx-skeleton-loader [theme]="{ width: '170px',  height: '10px' }">
              </ngx-skeleton-loader>
              <br />
              <ngx-skeleton-loader [theme]="{ width: '140px',  height: '10px' }">
              </ngx-skeleton-loader>
            </td>
            <td width="10%">
              <ngx-skeleton-loader [theme]="{width: '50px',height: '15px'}">
              </ngx-skeleton-loader><br />
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td width="16%">
              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '50px', height: '50px' }">
              </ngx-skeleton-loader>
            </td>
            <td width="74%">
              <ngx-skeleton-loader [theme]="{width: '250px',height: '15px'}">
              </ngx-skeleton-loader><br />
              <ngx-skeleton-loader [theme]="{ width: '170px',  height: '10px' }">
              </ngx-skeleton-loader>
              <br />
              <ngx-skeleton-loader [theme]="{ width: '140px',  height: '10px' }">
              </ngx-skeleton-loader>
            </td>
            <td width="10%">
              <ngx-skeleton-loader [theme]="{width: '50px',height: '15px'}">
              </ngx-skeleton-loader><br />
            </td>
          </tr>
        </table>

      </div>

      <div *ngIf="!isLoading">
        <div class="sub-title animated bounceInLeft delay-0.2s" *ngIf="viewNewLabel">
          Nuevas
          <div *ngFor="let item of dataNotificationActSin" style="padding: 10px;">
            <div *ngIf="item.StatusMsj === '0'" class="animated bounceInLeft delay-0.2s">
              <table width="100%">
                <tr>
                  <td width="18%">
                    <ngx-avatar facebookId="{{item.PhotoFrom}}" size="60" *ngIf="item.avatar_type.platform === 1">
                    </ngx-avatar>
                    <ngx-avatar src="{{item.PhotoFrom}}" size="60" *ngIf="item.avatar_type.platform === 0"></ngx-avatar>
                  </td>
                  <td width="72%">
                    <div class="title-notification">{{ item.NameFrom}}</div>
                    <div class="title-notification-read">{{ item.Descrip }}</div>
                    <div class="descrip-notification">{{ item.Fecha_letras.salida }}</div>
                    <div class="relative-time-notification">hace {{item.Fecha_letras.timeRelative}}</div>
                  </td>
                  <td width="10%">
                    <div align="right" style="font-size: 10px;color: #484848;">
                      <mat-icon class="ico-delete" (click)="deleteNotification(item.IdNotification)">close</mat-icon>
                    </div>
                  </td>
                </tr>
              </table>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>


        <div class="sub-title animated bounceInLeft delay-0.2s" *ngIf="viewOldLabel">
          Anteriores
          <div *ngFor="let item of dataNotificationAct" style="padding: 10px;">
            <div *ngIf="item.StatusMsj === '1'" class="animated bounceInLeft delay-0.2s">
              <table width="100%">
                <tr>
                  <td width="18%">
                    <ngx-avatar facebookId="{{item.PhotoFrom}}" size="60" *ngIf="item.avatar_type.platform === 1">
                    </ngx-avatar>
                    <ngx-avatar src="{{item.PhotoFrom}}" size="60" *ngIf="item.avatar_type.platform === 0"></ngx-avatar>
                  </td>
                  <td width="72%">
                    <div class="title-notification">{{ item.NameFrom}}</div>
                    <div class="title-notification-read">{{ item.Descrip }}</div>
                    <div class="descrip-notification">{{ item.Fecha_letras.salida }}</div>
                    <div class="relative-time-notification"><b>{{item.Fecha_letras.timeRelative}}</b></div>
                  </td>
                  <td width="10%">
                    <div align="right" style="font-size: 10px;color: #484848;">
                      <mat-icon class="ico-delete" (click)="deleteNotification(item.IdNotification)">close</mat-icon>
                    </div>
                  </td>
                </tr>
              </table>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>


  <mat-menu #menuUser="matMenu">
    <button mat-menu-item *ngFor="let item of dataTranslateMenu ">
      <mat-icon style="color: #536dfe;">{{item.ICO}}</mat-icon>
      <span>{{item.OPCION}}</span>
    </button>
  </mat-menu>

  <div class="nav-mobile fixed h-100vh w-100% z-4 t0 r0 l0 b0 hidden-md hidden-lg js-nav-mobile">
    <div class="nav-mobile-overlay fixed h-100vh w-100% t0 r0 l0 b0 bg-blue-100 js-nav-mobile-overlay"></div>
    <header class="nav-mobile-drawer bg-white w-80% h-100% overflow-y-auto">
      <div class="flex flex-align-center">
        <a href="/" class="flex pt4 ph4-with-safe-area pb5 flex-grow">
          <img src="assets/img/logo.png" width="260" height="auto">
        </a>
      </div>
    </header>
  </div>

</div>

<ng-template #example let-title="title">
  <div align="center" style="padding-left: 55px;">
    <img src="../../../assets/img/notification_ico.png" width="auto" height="140">
  </div>
  <div class="container-notification">
    <div class="header-notification">
      <div class="label-ads" *ngIf="viewUser">Hola! Tienes un mensaje de:</div>
      <ngx-skeleton-loader *ngIf="!viewUser" [theme]="{width: '100%', 'border-radius': '0', height: '10px'}">
      </ngx-skeleton-loader>


      <table *ngIf="viewUser">
        <tr>
          <td>
            <ngx-avatar facebookId="{{dataFrom?.Photo}}" size="30" *ngIf="dataFrom?.avatar_type.platform === 1">
            </ngx-avatar>
            <ngx-avatar src="{{dataFrom?.Photo}}" size="30" *ngIf="dataFrom?.avatar_type.platform === 0"></ngx-avatar>
          </td>
          <td style="padding-left: 5px;" *ngIf="viewUser">
            <span *ngIf="viewUser">{{dataFrom?.Name}} </span><br />
            <span class="detail" *ngIf="dataFrom?.Status === 'ON'">Conectado</span>
            <span class="detail" *ngIf="dataFrom?.Status === 'OFF'">Desconectado</span>
            <span class="detail" *ngIf="dataFrom?.UserType === 1"> (Usuario de {{dataFrom?.Commune}})</span>
            <span class="detail" *ngIf="dataFrom?.UserType === 2"> (Conductor de {{dataFrom?.Commune}})</span>
          </td>
        </tr>
      </table>

    </div>
    <div class="detail-message" *ngIf="viewUser">Dice: {{message}}</div>
    <ngx-skeleton-loader *ngIf="!viewUser" [theme]="{width: '100%', 'border-radius': '0', height: '80px'}">
    </ngx-skeleton-loader>
  </div>

</ng-template>