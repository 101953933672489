import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {APP_BASE_HREF} from '@angular/common';

const routes: Routes = [
    {
        path: 'we-will-meet-soon',
        loadChildren: () => import('../app/we-will-meet-soon/we-will-meet-soon.module').then(m => m.WeWillMeetSoonModule)
    },
    {
        path: '',
        redirectTo: 'we-will-meet-soon',
        pathMatch: 'full'
    },
    {
        path: 'what-is',
        loadChildren: () => import('../app/what-is/what-is.module').then(m => m.WhatIsModule)
    },
    {
        path: 'home',
        loadChildren: () => import('../app/home/pronto.module').then(m => m.ProntoModule)
    },
    {
        path: 'how-we-work',
        loadChildren: () => import('../app/how-we-work/how-we-work.module').then(m => m.HowWeWorkModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('../app/contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'search-routes',
        loadChildren: () => import('../app/search/search-routes/search-routes.module').then(m => m.SearchRoutesModule)
    },
    {
        path: 'search-routes-map',
        loadChildren: () => import('../app/search/search-routes-map/search-routes-map.module').then(m => m.SearchRoutesMapModule)
    },
    {
        path: 'search-packages',
        loadChildren: () => import('../app/search/search-packages/search-packages.module').then(m => m.SearchPackagesModule)
    },
    {
        path: 'chat',
        loadChildren: () => import('../app/chat/chat.module').then(m => m.ChatModule)
    },
    {
        path: 'route-profile/:id',
        loadChildren: () => import('../app/route-profile/profile-route.module').then(m => m.ProfileRouteModule)
    },
    {
        path: 'package-profile/:id',
        loadChildren: () => import('../app/package-profile/package-profile.module').then(m => m.PackageProfileModule)
    },
    {
        path: 'accept-package/:id',
        loadChildren: () => import('../app/drivers/accept-package/accept-package.module').then(m => m.AcceptPackageModule)
    },
    {
        path: 'package-tracking/:idPackage/:idDriver',
        loadChildren: () => import('../app/package-tracking/package-tracking.module').then(m => m.PackageTrackingModule)
    },
    {
        path: 'multiple-tracking/:idUser',
        loadChildren: () => import('../app/multiple-tracking/multiple-tracking.module').then(m => m.MultipleTrackingModule)
    },
    {
        path: 'validate-user/:email',
        loadChildren: () => import('../app/validate-user/validate-user.module').then(m => m.ValidateUserModule)
    },
    {
        path: 'unlock-account/:email',
        loadChildren: () => import('../app/unlock-account/unlock-account.module').then(m => m.UnlockAccountModule)
    },
    {
        path: 'feedback/:id',
        loadChildren: () => import('../app/pay-feedback/pay-feedback.module').then(m => m.PayFeedbackModule)
    },
    {
        path: 'error-403',
        loadChildren: () => import('../app/errors/403/error-page/error-page.module').then(m => m.ErrorPageModule)
    },
    {
        path: 'error-500',
        loadChildren: () => import('../app/errors/500/error-page/error-page.module').then(m => m.ErrorPageModule)
    },
    {
        path: '**',
        loadChildren: () => import('../app/errors/404/error-page/error-page.module').then(m => m.ErrorPageModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
    exports: [RouterModule],
    providers: [{provide: APP_BASE_HREF, useValue: '/'}]
})

export class AppRoutingModule {}